import React from 'react';
import PropTypes from 'prop-types';

const TreeSelect = ({ className, name, value, onChange, disabled, emptyText, trees }) => {

  const handleChange = (event) => {
    var name = event.target.name;
    var value = event.target.value;
    onChange(name, value);
  };

  const options = (
    <>
      {trees && trees.map((def) => (
        <option key={def.name} value={def.name}>
          {def.name}
        </option>
      ))}
    </>
  );

  return <>
    <select className={className ?? 'form-select'} name={name} value={value} disabled={disabled} onChange={handleChange}>
      {value === '' && <option value="" >{emptyText ?? 'Default Tree'}</option>}
      {options}
    </select>
  </>
}

export default TreeSelect;



TreeSelect.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool.isRequired,
  emptyText: PropTypes.string.isRequired,
  trees: PropTypes.any.isRequired
}
